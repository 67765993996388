import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../core/service/auth/auth.service";


@Injectable()
export class ErrorService {
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  private static errorMsg: any = {
    "400": "逻辑错误",
    "401": "（未授权）请求要求身份验证",
    "403": "登录信息失效，请重新登录。",
    "404": "未找到服务器资源",
    "409": "服务器在完成请求时发生冲突。",
    "500": "系统错误"
  };

  private static errorCode: any = {
    param_error_telephone: "手机格式不正确",
    param_error: "参数错误"
  };

  getErrorText(e) {
    const status = e['status'];
    const error: any = e['error'];
    if (status === 403 || status === 401) {
      this.auth.clear();
      this.router.navigate(['/app-insurance-login']);
    } else if (status === 400 || status === 409 || status === 404) {
      if (error) {
        if (error.errmsg) {
          return error.errmsg;
        } else {
          if (ErrorService.errorCode[error.errcode]) {
            return ErrorService.errorCode[error.errcode];
          } else {
            return error.errcode;
          }
        }
      } else {
        return `未知错误：${status}`;
      }

    } else if (status === 500) {
      return `系统错误:${status}`;
    } else if (status === 503) {
      return `服务器繁忙:${status}`;
    }
    return `未知错误`;
  }

}
