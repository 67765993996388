import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { IndexRoutingModule } from './index.routing.module';
import { IndexService } from './index.service';
import { IndexComponent } from './index.component';
/**
 * Created by AGUANG on 2017/2/15.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angular2-qrcode';
import { VehicleTrajectoryModule } from '../vehicle-trajectory/vehicle-trajectory.module';
import { ErrorService } from '../ErrorService/error.service';

@NgModule({
  imports: [
    CommonModule, RouterModule, FormsModule, ReactiveFormsModule,
    SharedModule, QRCodeModule, IndexRoutingModule, VehicleTrajectoryModule
  ],
  declarations: [IndexComponent],
  providers: [IndexService,ErrorService],
  exports: [IndexComponent, RouterModule]
})
export class IndexModule { }
