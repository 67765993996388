import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver/FileSaver';
import * as XLSX from 'xlsx';
import { UserService } from './user.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()
export class ExcelService {
  
  constructor(private userService: UserService) {

  }

  /**
   *
   * @param excelFileName 文件名
   * @param headers 文件头
   * @param sheetNames sheet名
   * @param obj  所提供的数据
   */
  public exportAsExcelFile(excelFileName: string, sheetNames: string[], obj: any[]): void {

    if (typeof excelFileName == 'undefined' || excelFileName == null || excelFileName == '') {
      throw new Error('please input excelFileName name');
    }

    if (obj == null || obj.length == 0) {
      throw new Error('no datas show');
    }

    const workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };

    obj.forEach((value, index) => {
      if (sheetNames == null) {
        workbook.SheetNames.push('sheet' + index);
      } else {
        workbook.SheetNames.push(sheetNames[index]);
      }
      if (value === null || value.length === 0) {

      } else {
        const tempSheet = XLSX.utils.json_to_sheet(value);
        workbook.Sheets[workbook.SheetNames[index]] = tempSheet;
      }

    });

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {

    const buf = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(buf);


    for (let i = 0; i !== buffer.length; ++i) {
      view[i] = buffer.charCodeAt(i) & 0xFF;
    }


    const data: Blob = new Blob([buf], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);

    // const data: Blob = new Blob( [fileName] , {
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    // FileSaver.saveAs(data, fileName+EXCEL_EXTENSION)
  }

  // 最近12个月
  getLast12Months(day: Date) {
    const last12Months = [];
    const today = new Date(day.getTime());
    today.setMonth(today.getMonth() + 1);
    for (let i = 0; i < 12; i++) {
      const lastMonth = today.setMonth(today.getMonth() - 1);
      last12Months[11 - i] = today.getFullYear().toString().slice(2, 4) + '/' + (today.getMonth() + 1);
    }
    return last12Months;
  }


  // 风控分析报告统计

  public excelDataFile(lastMonth:string, lastTwelveMonth: string[], r: any) {
    let year = new Date(lastMonth).getFullYear();
    let month = new Date(lastMonth).getMonth()+1;
    

    const escorwList: any[] = []; // 机构托管数量统计
    const overSpeedList: any[] = []; // 车均百公里超速
    const fatigueList: any[] = []; // 车均疲劳时长
    const overSpeedRemindList: any[] = []; // 超速提醒统计
    const remindList1: any[] = []; // 超速提醒计数
    const fatigueremindList: any[] = []; // 疲劳提醒统计
    const remindList2: any[] = []; // 疲劳提醒计数
    const milesStatisticalList: any[] = []; // 行驶里程统计
    const driveTimeStatisticalList: any[] = []; // 分时段里程统计
    const driverAreaStatisticalList: any[] = []; // 道路结构里程统计
    const fatigueTimesList: any[] = []; // 疲劳次数

    for (let i = 0; i < 12; i++) {
      const escorwData = {
        '年月': lastTwelveMonth[i],
        '托管车辆': r.escorw.escrowVehicleList[i],
        '上线车辆数': r.escorw.onlineList[i],
        '车辆上线率': r.escorw.onlinePresentList[i]
      };

      const overSpeedData = {
        '年月': lastTwelveMonth[i],
        '车均百公里超速次数(行业均值)': r.overSpeedListAverage[i],
        '【第1批】车均百公里超速次数': r.overSpeedList1[i],
        '【第2批】车均百公里超速次数': r.overSpeedList2[i],
        '【第3批】车均百公里超速次数': r.overSpeedList3[i],
        '【第4批】车均百公里超速次数': r.overSpeedList4[i],
      };

      const fatigueData = {
        '年月': lastTwelveMonth[i],
        '疲劳时长(行业均值)': r.fatigueAverage[i],
        '【1】疲劳时长': r.fatigueList1[i],
        '【2】疲劳时长': r.fatigueList2[i],
        '【3】疲劳时长': r.fatigueList3[i],
        '【4】疲劳时长': r.fatigueList4[i],
      };

      const overSpeedRemindData = {
        '年月': lastTwelveMonth[i],
        'TTS提醒次数': r.overSpeedRemindOption.TTS[i],
        'WX提醒次数': r.overSpeedRemindOption.WX[i],
        'MSG提醒次数': r.overSpeedRemindOption.MSG[i],
        'Phone提醒次数': r.overSpeedRemindOption.Phone[i],
      };

      const remindData1 = {
        '年月': lastTwelveMonth[i],
        '【1】TTS提醒次数': r.overSpeedRemindOption.Remind.tts.one[i],
        '【2】TTS提醒次数': r.overSpeedRemindOption.Remind.tts.two[i],
        '【3】TTS提醒次数': r.overSpeedRemindOption.Remind.tts.three[i],
        '【4】TTS提醒次数': r.overSpeedRemindOption.Remind.tts.four[i],

        '【1】微信提醒次数': r.overSpeedRemindOption.Remind.wx.one[i],
        '【2】微信提醒次数': r.overSpeedRemindOption.Remind.wx.two[i],
        '【3】微信提醒次数': r.overSpeedRemindOption.Remind.wx.three[i],
        '【4】微信提醒次数': r.overSpeedRemindOption.Remind.wx.four[i],

        '【1】短信提醒次数': r.overSpeedRemindOption.Remind.msg.one[i],
        '【2】短信提醒次数': r.overSpeedRemindOption.Remind.msg.two[i],
        '【3】短信提醒次数': r.overSpeedRemindOption.Remind.msg.three[i],
        '【4】短信提醒次数': r.overSpeedRemindOption.Remind.msg.four[i],

        '【1】电话提醒次数': r.overSpeedRemindOption.Remind.phone.one[i],
        '【2】电话提醒次数': r.overSpeedRemindOption.Remind.phone.two[i],
        '【3】电话提醒次数': r.overSpeedRemindOption.Remind.phone.three[i],
        '【4】电话提醒次数': r.overSpeedRemindOption.Remind.phone.four[i],
      };



      const fatigueRemindData = {
        '年月': lastTwelveMonth[i],
        'TTS提醒次数': r.fatigueRemindOption.TTS[i],
        'WX提醒次数': r.fatigueRemindOption.WX[i],
        'MSG提醒次数': r.fatigueRemindOption.MSG[i],
        'Phone提醒次数': r.fatigueRemindOption.Phone[i],
      };

      const remindData2 = {
        '年月': lastTwelveMonth[i],
        '【1】TTS提醒次数': r.fatigueRemindOption.Remind.tts.one[i],
        '【2】TTS提醒次数': r.fatigueRemindOption.Remind.tts.two[i],
        '【3】TTS提醒次数': r.fatigueRemindOption.Remind.tts.three[i],
        '【4】TTS提醒次数': r.fatigueRemindOption.Remind.tts.four[i],

        '【1】微信提醒次数': r.fatigueRemindOption.Remind.wx.one[i],
        '【2】微信提醒次数': r.fatigueRemindOption.Remind.wx.two[i],
        '【3】微信提醒次数': r.fatigueRemindOption.Remind.wx.three[i],
        '【4】微信提醒次数': r.fatigueRemindOption.Remind.wx.four[i],

        '【1】短信提醒次数': r.fatigueRemindOption.Remind.msg.one[i],
        '【2】短信提醒次数': r.fatigueRemindOption.Remind.msg.two[i],
        '【3】短信提醒次数': r.fatigueRemindOption.Remind.msg.three[i],
        '【4】短信提醒次数': r.fatigueRemindOption.Remind.msg.four[i],

        '【1】电话提醒次数': r.fatigueRemindOption.Remind.phone.one[i],
        '【2】电话提醒次数': r.fatigueRemindOption.Remind.phone.two[i],
        '【3】电话提醒次数': r.fatigueRemindOption.Remind.phone.three[i],
        '【4】电话提醒次数': r.fatigueRemindOption.Remind.phone.four[i],
      };

      const milesStatisticalData = {
        '年月': lastTwelveMonth[i],
        '总里程-行业均值': r.milesStatistical.total.average[i],
        '总里程-第1批': r.milesStatistical.total.one[i],
        '总里程-第2批': r.milesStatistical.total.two[i],
        '总里程-第3批': r.milesStatistical.total.three[i],
        '总里程-第4批': r.milesStatistical.total.four[i],

        '平均里程-行业均值': r.milesStatistical.average.average[i],
        '平均里程-第1批': r.milesStatistical.average.one[i],
        '平均里程-第2批': r.milesStatistical.average.two[i],
        '平均里程-第3批': r.milesStatistical.average.three[i],
        '平均里程-第4批': r.milesStatistical.average.four[i],
      };


      const driveTimeStatisticalData = {
        '年月': lastTwelveMonth[i],
        '总里程-0-6点(凌晨)': r.driveTimeStatistical.total.zero_six[i],
        '总里程-7-12点(上午)': r.driveTimeStatistical.total.seven_twelve[i],
        '总里程-13-17点(下午)': r.driveTimeStatistical.total.thirteen_seventeen[i],
        '总里程-18-20点(傍晚)': r.driveTimeStatistical.total.eighteen_twenty[i],
        '总里程-21-23点(夜间)': r.driveTimeStatistical.total.twentyOne_twentyThree[i],

        '里程占比-0-6点(凌晨)': r.driveTimeStatistical.present.zero_six[i],
        '里程占比-7-12点(上午)': r.driveTimeStatistical.present.seven_twelve[i],
        '里程占比-13-17点(下午)': r.driveTimeStatistical.present.thirteen_seventeen[i],
        '里程占比-18-20点(傍晚)': r.driveTimeStatistical.present.eighteen_twenty[i],
        '里程占比-21-23点(夜间)': r.driveTimeStatistical.present.twentyOne_twentyThree[i],

        '平均里程-0-6点(凌晨)': r.driveTimeStatistical.average.zero_six[i],
        '平均里程-7-12点(上午)': r.driveTimeStatistical.average.seven_twelve[i],
        '平均里程-13-17点(下午)': r.driveTimeStatistical.average.thirteen_seventeen[i],
        '平均里程-18-20点(傍晚)': r.driveTimeStatistical.average.eighteen_twenty[i],
        '平均里程-21-23点(夜间)': r.driveTimeStatistical.average.twentyOne_twentyThree[i],
      };


      const driverAreaStatisticalData = {
        '年月': lastTwelveMonth[i],
        '总里程-高速公路': r.driverAreaStatistical.total.highway[i],
        '总里程-国道': r.driverAreaStatistical.total.nationalway[i],
        '总里程-省道': r.driverAreaStatistical.total.provinceway[i],
        '总里程-其他': r.driverAreaStatistical.total.others[i],

        '里程占比-高速公路': r.driverAreaStatistical.present.highway[i],
        '里程占比-国道': r.driverAreaStatistical.present.nationalway[i],
        '里程占比-省道': r.driverAreaStatistical.present.provinceway[i],
        '里程占比-其他': r.driverAreaStatistical.present.others[i],

        '平均里程-高速公路': r.driverAreaStatistical.average.highway[i],
        '平均里程-国道': r.driverAreaStatistical.average.nationalway[i],
        '平均里程-省道': r.driverAreaStatistical.average.provinceway[i],
        '平均里程-其他': r.driverAreaStatistical.average.others[i],
      };

      const fatigueTime = {
        '疲劳次数': r.FatigueTimesList.averageFatigueTimesArray[i],
        '行业均值疲劳次数': r.FatigueTimesList.companyAverageFatigueTimesArray[i]
      };

      overSpeedList.push(overSpeedData);
      escorwList.push(escorwData);
      fatigueList.push(fatigueData);
      overSpeedRemindList.push(overSpeedRemindData);
      remindList1.push(remindData1);
      fatigueremindList.push(fatigueRemindData);
      remindList2.push(remindData2);
      milesStatisticalList.push(milesStatisticalData);
      driveTimeStatisticalList.push(driveTimeStatisticalData);
      driverAreaStatisticalList.push(driverAreaStatisticalData);
      fatigueTimesList.push(fatigueTime);
    }


    this.exportAsExcelFile( year + '年' + month + '月-车辆风控分析报告',
      ['机构托管车辆统计', '车均百公里超速', '车均疲劳时长', '超速提醒统计', '超速提醒信息数量', '疲劳提醒统计', '疲劳提醒信息数量', '行驶里程统计', '分时段里程统计', '道路结构里程统计', '疲劳驾驶'],
      [escorwList, overSpeedList, fatigueList, overSpeedRemindList, remindList1, fatigueremindList, remindList2, milesStatisticalList, driveTimeStatisticalList, driverAreaStatisticalList, fatigueTimesList]);

  }


}
